









































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Atributo, AtributoDoProduto } from '@/models'
import SeletorDeAtributo from '@/components/produto/SeletorDeAtributo.vue'

@Component({
	components: {
		SeletorDeAtributo,
	},
})
export default class CamposDeAtributoDoProduto extends Vue {
	@Prop() value!: AtributoDoProduto
	@Prop({ type: Boolean }) podeCriar!: boolean
	@Prop({ type: Boolean }) podeEditar!: boolean
	@Prop({ type: Boolean, default: false }) podeExcluir!: boolean
	@Prop({ type: Array, default: () => [] }) ignores?: Atributo[]

	set atributoDoProduto(atributoDoProduto: CamposDeAtributoDoProduto['value']) {
		this.$emit('input', atributoDoProduto)
	}

	get atributoDoProduto() {
		return this.value
	}

	selecionarOpcaoDeAtributo(atributo: OpcaoDeAtributo | undefined) {
		this.atributoDoProduto = {
			...this.atributoDoProduto,
			atributo: atributo ? atributo : { id: '', nome: '' },
		}
	}
}

export type OpcaoDeAtributo = {
	id: string
	nome: string
}

