

















































































































import { Vue, Component, PropSync, Watch } from 'vue-property-decorator'
import { Fragment } from 'vue-fragment'
import { ProdutoComposto, Produto } from '@/models'
import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import { ParametrosParaAutenticacao } from '@/models/RegraDeNegocio'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'


@Component({
	components: {
		Fragment,
		BuscaDeProdutoDropdown,
		CampoDecimal,
	},
})
export default class CamposDeProdutoComposto extends Vue {
	@PropSync('value') produto!: ProdutoComposto
	campoDeDesconto!: HTMLInputElement

	produtoSelecionado: Produto | null = null
	podeAlterarCampoDeDesconto = false

	@Watch('produtoSelecionado')
	inserirProduto(produto: CamposDeProdutoComposto['produtoSelecionado']) {
		if (!produto) return

		const produtoJaAdicionado = this.produto.itens.some(
			item => item.produto.id === produto.id,
		)

		if (produtoJaAdicionado) {
			this.produto.itens.map(item => {
				item.produto.id === produto.id ? item.quantidade++ : item
			})
		} else if (this.produto.itens) {
			this.produto.itens.push({
				quantidade: 1,
				produto,
				desconto: {
					isPercentual: true,
					valor: 0,
				},
				idConjunto: null,
				tags:[],
			})
		}
		this.$nextTick(() => {
			this.produtoSelecionado = null
		})
	}
	remover(indice: any){
		this.produto.itens.splice(indice, 1)
	}
	get podeConcederDesconto() {
		return UserLoginStore.permiteRegraDeNegocio('pode-conceder-desconto')
	}
	
	get podeExcluirItemComposto(){
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso('deletar','excluir-item-composto')
	}

	async concederDesconto() {
		const params: ParametrosParaAutenticacao = {
			lojaId: VendaModule.lojaDaVenda?.id,
			regra: 'pode-conceder-desconto',
		}
		if (!this.podeConcederDesconto && !await VendaModule.autenticarAcao(params)) return
		this.podeAlterarCampoDeDesconto = true
		this.campoDeDesconto.focus()
	}

	get totalDosItens() {
		return this.produto.itens.reduce(
			(total, { produto, quantidade }) =>
				total + (produto.preco || 0) * quantidade ,
			0,
		)
	}

	descontoEValido(item) {
		return () => {
			if (!item.desconto) return true
			if (!item.desconto.isPercentual && item.desconto.valor > ((item.preco * item.quantidade) - 0.01)) {
				return 'Valor maior que o total da venda'
			}

			if (item.desconto.isPercentual && item.desconto.valor > 99.99) {
				return 'Valor não pode ser maior que 99.99%'
			}

			return true
		}
	}

	setPercentual(valor: boolean, item) {
		if (!item.desconto) return
		item.desconto.isPercentual = valor
	}
}
