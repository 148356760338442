





import { Vue, Component, PropSync } from 'vue-property-decorator'
import { Fragment } from 'vue-fragment'
import { ProdutoPadrao } from '@/models'

@Component({
	components: {
		Fragment,
	},
})
export default class CamposDeProdutoPadrao extends Vue  {
	@PropSync('value') produto!: ProdutoPadrao
}

