

























































































































































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import { displayVariantes } from '@/shareds/produto-shareds'
import { Variante, ProdutoComVariantes, ImagensProduto } from '@/models'
import SeletorDeTag from '@/components/produto/SeletorDeTag.vue'
import SeletorDeUnidadeDeMedida from '@/components/produto/SeletorDeUnidadeDeMedida.vue'

@Component({
	components: {
		SeletorDeTag,
		SeletorDeUnidadeDeMedida,
	},
})
export default class PanelDeEdicaoDaVariante extends Vue {
	@PropSync('value') variante!: Variante
	@Prop({ type: Boolean }) skuAutomatico!: boolean
	@Prop({ type: Object }) produtoPai!: ProdutoComVariantes
	
	displayVariantes = displayVariantes

	img: ImagensProduto | null = null
	
	async created() {
		if(!this.variante.urlImagens) return
		const padrao = this.variante.urlImagens.filter(imagem => imagem.padrao)[0]
		this.img = padrao
	}

	@Watch('produtoPai.descontoVarejo')
	onChangeProdutoPaiDescontoVarejo(){
		if(this.variante.descontoVarejo != this.produtoPai.descontoVarejo)
			this.variante.descontoVarejo = this.produtoPai.descontoVarejo
	}

	@Watch('produtoPai.tags')
	onChangeProdutoPaiTags(){
		if(this.produtoPai.tags != this.variante.tags){
			this.variante.tags = this.produtoPai.tags
		}
	}

	@Watch('produtoPai.unidadeDeConversao')
	onChangeProdutoPaiUnidadeDeConversao(){
		if(this.produtoPai.unidadeDeConversao != this.variante.unidadeDeConversao){
			this.variante.unidadeDeConversao = this.produtoPai.unidadeDeConversao
		}
	}

	@Watch('produtoPai.fatorDeConversao')
	onChangeProdutoPaiFatorDeConversao(){
		if(this.produtoPai.fatorDeConversao != this.variante.fatorDeConversao){
			this.variante.fatorDeConversao = this.produtoPai.fatorDeConversao
		}
	}

	changeVariante() {
		this.$emit('onChangeVariante', this.variante)
	}
}

